//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    title: {
      type: String,
      default: null
    },
    enableBtnDel: {
      type: Boolean,
      default: true
    }
  }
};