<template>
  <div>
    <SettingModal ref="SettingModal" @update="onSettingData" />

    <StatFilter ref="StatFilter" :PERIOD="cycle" @query="onQuery">
      <CCardHeader slot="header">
        <CRow class="justify-content-between px-2">
          <span>
            <CButton color="light" @click="onShowFilter"><CIcon name="expand" /></CButton>
            <CButton class="ml-2" color="light" @click="onResetFilter"><CIcon name="refresh" />{{$t('cloud.title.filter_reset')}}</CButton>
          </span>
          <CDropdown color="light">
            <span slot="toggler-content"><CIcon class="mr-1" name="cil-settings" size="sm" />{{$t('cloud.title.setting')}}</span>
            <CDropdownItem @click="onSettingOpen"><CIcon class="mr-2" name="cil-bar-chart" />{{$t('cloud.title.view_setting')}}</CDropdownItem>
            <CDropdownItem @click="onExportOpen"><CIcon class="mr-2" name="file_download" />{{$t('button.export')}}</CDropdownItem>
          </CDropdown>
          <!-- <CButton color="light" @click="onExportOpen"><CIcon class="mr-2" name="file_download" />{{$t('button.export')}}</CButton> -->
        </CRow>
      </CCardHeader>
    </StatFilter>

    <CRow v-if="load_done">
      <CCol md="6" lg="6" xl="6" xxl="3">
        <CardSimpleNumber :header="$t('cloud.title.occupancy_visitor_count')" :text="`${stats.visitor_total}`">
          <h5 :class="{'text-success':todayVisitIncrease >= 0,'text-danger':todayVisitIncrease<0}">
            <svgicon :name="todayVisitIncrease >= 0 ? 'arrow_drop_up':'arrow_drop_down'" width="23" height="23" />
            <span>{{Math.sqrt(todayVisitIncrease*todayVisitIncrease)}}</span>
          </h5>
        </CardSimpleNumber>
      </CCol>
      <CCol md="6" lg="6" xl="6" xxl="3">
        <CardSimpleNumber :header="$t('cloud.title.occupancy_average')" :text="`${stats.occupancy_avg}`">
          <h5 :class="{'text-success':avgOccupancyIncrease >= 0,'text-danger':avgOccupancyIncrease<0}">
            <svgicon :name="avgOccupancyIncrease >= 0 ? 'arrow_drop_up':'arrow_drop_down'" width="23" height="23" />
            <span>{{avgOccupancyIncrease}}%</span>
          </h5>
        </CardSimpleNumber>
      </CCol>
      <CCol md="6" lg="6" xl="6" xxl="3">
        <CardSimpleNumber :header="$t('cloud.title.occupancy_max')" :text="`${stats.occupancy_max}`">
          <h5 class="text-secondary">
            {{stats.occupancy_max_time}}
          </h5>
        </CardSimpleNumber>
      </CCol>
      <CCol md="6" lg="6" xl="6" xxl="3">
        <CardSimpleNumber :header="$t('cloud.title.event_count')" :text="`${stats.occupancy_triggered}`">
          <h5 :class="{'text-success':triggerIncrease >= 0,'text-danger':triggerIncrease<0}">
            <svgicon :name="triggerIncrease >= 0 ? 'arrow_drop_up':'arrow_drop_down'" width="23" height="23" />
            <span>{{triggerIncrease}}%</span>
          </h5>
        </CardSimpleNumber>
      </CCol>
      <CCol xl="12" xxl="6">
        <CardLineCharts
          :title="$t('cloud.title.occupancy_count')"
          :stats="[stats.occupancy_counts]"
          :labels="[$t('cloud.title.day_today')]"
          :colors="['#658e44']"
          :bgColors="['#658e44']"
          :lineStyle="['line']"
          :legend="false"
        />
      </CCol>
      <CCol xl="12" xxl="6">
        <CardLineCharts
          :title="$t('cloud.title.occupancy_inout')"
          :stats="[stats.occupancy_in, stats.occupancy_out]"
          :labels="[$t('cloud.title.occupancy_in'), $t('cloud.title.occupancy_out')]"
          :colors="['#355ed4', '#b0534c']"
          :bgColors="['#355ed4','#b0534c']"
        />
      </CCol>
    </CRow>
    <div v-loading="!load_done" v-if="!load_done" />
  </div>
</template>

<script>
import {mapState} from 'vuex'
import axios from 'axios'
import qs from 'qs'
import StatFilter from './StatFilter.vue'
import CardSimpleNumber from '@/components/cards/SimpleNumber.vue'
import CardLineCharts from '@/components/cards/LineCharts.vue'
import SettingModal from '@/components/modals/SettingModal.vue'

export default {
  name: 'AppsOccupancyAnalytics',

  components: {
    StatFilter,
    CardSimpleNumber,
    CardLineCharts,
    SettingModal
  },

  computed: {
    ...mapState([
      'capability'
    ]),
    todayVisitIncrease() {
      return (this.stats.visitor_total - this.stats.visitor_total_yesterday);
    },
    avgOccupancyIncrease() {
      let inc_percent = Math.round((this.stats.occupancy_avg - this.stats.occupancy_avg_yesterday) * 100 / Math.max(this.stats.occupancy_avg_yesterday, 1) * 100);
      inc_percent /= 100;
      return inc_percent;
    },
    triggerIncrease() {
      return 0;
    },
  },
  data() {
    return {
      load_done: false,
      loading: {
        visitor: true
      },
      cycle: 'daily',
      filters: null,
      stats: {
        visitor_total: 0,
        visitor_total_yesterday: 0,
        occupancy_avg: 0,
        occupancy_avg_yesterday: 0,
        occupancy_max: 0,
        occupancy_max_time: null,
        occupancy_triggered: 0,
        occupancy_triggered_yesterday: 0,
        occupancy_counts: [],
        occupancy_in: [],
        occupancy_out: [],
      }
    }
  },
  methods: {
    onQuery(stat_filter) {
      this.load_done = false;
      this.filters = stat_filter;
      if (this.filters && this.filters.date) {
        this.statOccupancy();
      }
    },
    buildQs(date_before) {
      let query_string = '';
      const params = {};
      if (this.filters) {
        params.branch = this.filters.branch;
        params.device = this.filters.device;
        params.counter = this.filters.counter;
        params.t_from = this.$utility.GetDateTimeStr("$yyyy$mm$dd$HH$MM$ss", this.filters.dates[0]);
        params.t_to = this.$utility.GetDateTimeStr("$yyyy$mm$dd$HH$MM$ss", this.filters.dates[1]);
        params.interval = this.filters.unit;
      }
      let from_ts = this.filters.dates[0].getTime();
      let to_ts = this.filters.dates[1].getTime();
      for (let i=0; i<date_before; i++) {
        let temp_t_to = from_ts;
        let temp_t_from = temp_t_to - (to_ts-from_ts);
        from_ts = temp_t_from;
        to_ts = temp_t_to;
      }
      if (date_before) {
        params.t_from = this.$utility.GetDateTimeStr("$yyyy$mm$dd$HH$MM$ss", new Date(from_ts));
        params.t_to = this.$utility.GetDateTimeStr("$yyyy$mm$dd$HH$MM$ss", new Date(to_ts));
      }
      if (params) {
        query_string = '?' + qs.stringify(params);
      }
      return query_string;
    },
    statOccupancy() {
      const query_string = this.buildQs();
      const query_string_yesterday = this.buildQs(1);
      let loading_today = true;
      let loading_yesterday = true;

      this.loading.visitor = true;
      axios.get(`/api/counter/occupancy/stat/${query_string}`)
        .then(result => {
          // 방문자 총수
          this.stats.visitor_total = 0;
          for (const d of result.data) {
            this.stats.visitor_total += d.inc_sum;
          }

          // 평균 재실 인원 수
          let item_cnt = result.data.length;
          let cnt_0 = 0;
          let cnt_0_r = 0;
          for (let i=0; i<item_cnt; i++) {
            if (result.data[i].cum === 0) {
              cnt_0++;
              continue;
            }
            break;
          }
          for (let i=item_cnt-1; i>=0; i--) {
            if (result.data[i].cum === 0) {
              cnt_0_r++;
              continue;
            }
            break;
          }
          let cont_non0_cnt = (item_cnt-cnt_0-cnt_0_r);
          if (cont_non0_cnt <= 0) {
            this.stats.occupancy_avg = 0;
          } else {
            this.stats.occupancy_avg = Math.round(this.stats.visitor_total / cont_non0_cnt);
          }

          // 최대 재실 인원 수 및 시간대
          this.stats.occupancy_max = 0;
          result.data.map(el => {
            if (this.stats.occupancy_max < el.cum) {
              this.stats.occupancy_max = el.cum;
              let fmt = {
                daily: 'HH:00',
                weekly: '$day',
                monthly: '$mm/$dd',
                custom: '$mm/$dd'
              }
              let d = new Date(el.created_at);
              if (this.cycle === 'daily') {
                this.stats.occupancy_max_time = this.$utility.zonedDateTimeStr(d, this.capability.user_profile.timezone, fmt[this.cycle]);
              } else if (this.cycle === 'weekly') {
                this.stats.occupancy_max_time = this.$t(`cloud.title.dow_${this.$utility.zonedDateTimeStr(d, this.capability.user_profile.timezone, 'EEE').toLowerCase()}2`)
              } else {
                this.stats.occupancy_max_time = this.$utility.zonedDateTimeStr(d, this.capability.user_profile.timezone, 'MM/dd');
              }
            }
          })
          // 재실인원 추이
          this.stats.occupancy_counts = [];
          for (const el of result.data) {
            this.stats.occupancy_counts.push({
              value:Math.max(el.cum, 0),
              x:el.created_at
            });
          }
          // 입장 및 퇴장 수
          this.stats.occupancy_in = [];
          this.stats.occupancy_out = [];
          for (const el of result.data) {
            this.stats.occupancy_in.push({
              value:el.inc_sum,
              x:el.created_at
            });
            this.stats.occupancy_out.push({
              value:el.dec_sum,
              x:el.created_at
            });
          }
        })
        .catch(error => {
          console.error(error);
        })
        .finally(() => {
          loading_today = false;
          if (!loading_yesterday) {
            this.loading.visitor = false;
            this.checkLoading();
          }
        })
      axios.get(`/api/counter/occupancy/stat/${query_string_yesterday}`)
        .then(result => {
          this.stats.visitor_total_yesterday = 0;
          for (const d of result.data) {
            this.stats.visitor_total_yesterday += d.inc_sum;
          }
          // 평균 재실 인원 수
          // let total_sum = 0;
          // let cnt = 0;
          // for (const d of result.data) {
          //   const ts = new Date(d.created_at);
          //   // TODO. 운영시간 설정하고 체크하자. 임시로 7시라고 하자.
          //   if (ts.getHours() < 7) continue;
          //   cnt++;
          //   total_sum += d.cum;
          // }
          // this.stats.occupancy_avg_yesterday = Math.round(total_sum / Math.max(cnt,1));
          let item_cnt = result.data.length;
          let cnt_0 = 0;
          let cnt_0_r = 0;
          for (let i=0; i<item_cnt; i++) {
            if (result.data[i].cum === 0) {
              cnt_0++;
              continue;
            }
            break;
          }
          for (let i=item_cnt-1; i>=0; i--) {
            if (result.data[i].cum === 0) {
              cnt_0_r++;
              continue;
            }
            break;
          }
          let cont_non0_cnt = (item_cnt-cnt_0-cnt_0_r);
          if (cont_non0_cnt <= 0) {
            this.stats.occupancy_avg_yesterday = 0;
          } else {
            this.stats.occupancy_avg_yesterday = Math.round(this.stats.visitor_total_yesterday / cont_non0_cnt);
          }
        })
        .catch(error => {
          console.error(error);
        })
        .finally(() => {
          loading_yesterday = false;
          if (!loading_today) {
            this.loading.visitor = false;
            this.checkLoading();
          }
        })
    },
    checkLoading() {
      if (!this.loading.visitor) {
        setTimeout(() => {
          this.load_done = true;
        }, 1000);
      }
    },

    // 내보내기
    onExportOpen() {
      // this.$refs.ExportModal.open();
      let param = {
        stats: this.stats,
        cycle: this.cycle
      };
      let param_str = JSON.stringify(param);
      let param_encoded = window.btoa(unescape(encodeURIComponent(param_str)));
      this.$router.push(`/report/apps/occupancy?p=${param_encoded}`);
    },
    onSettingOpen() {
      this.$refs.SettingModal.open();
    },
    onSettingData() {
      const record = this.$refs.SettingModal.getData();
      this.cycle = record.cycle;
      setTimeout(() => {
        this.$refs.StatFilter.onQuery();
      }, 1);
    },
    onResetFilter() {
      this.$refs.StatFilter.onReset();
    },
    onShowFilter() {
      this.$refs.StatFilter.showFilter();
    }
  }
}
</script>