<template>
  <CModal
    :show.sync="show"
    :close-on-backdrop="false"
    :centered="true"
    size="lg"
    color="dark"
  >
    <el-form label-width="30%">
      <el-form-item :label="$t('cloud.title.view_cycle')" class="mb-0">
        <el-select v-model="record.cycle">
          <el-option
            v-for="opt in $resource.DATAVIEW_CYCLE_OPTS"
            :key="`dataview-cycle-opt-${opt.value}`"
            :label="$t(opt.label_key)"
            :value="opt.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('cloud.title.by_branch')" class="mb-0">
        <el-select value="" disabled>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('cloud.title.view_valid_time_range')">
        <el-select v-model="record.stime" disabled>
          <el-option :label="$utility.GetHourStr(i)" :value="i" v-for="i in $utility.Range(0, 24)" :key="`stime-opts-${i}`" />
        </el-select>&nbsp;-&nbsp;
        <el-select v-model="record.etime" disabled>
          <el-option :label="$utility.GetHourStr(i)" :value="i" v-for="i in $utility.Range(0, 24)" :key="`stime-opts-${i}`" />
        </el-select>
      </el-form-item>
    </el-form>
    <div>
      <slot name="property" />
    </div>

    <template #header>
      <h6 class="modal-title">{{$t('cloud.title.view_setting')}}</h6>
      <!-- <CButtonClose @click="show = false" class="text-white"/> -->
    </template>
    <template #footer>
      <CButton @click="close" color="secondary" class="mx-1">{{$t('button.cancel')}}</CButton>
      <CButton @click="onSubmit" color="primary">{{$t('button.apply')}}</CButton>
    </template>
  </CModal>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'SettingModal',
  computed: {
    ...mapState([
      'capability'
    ])
  },
  data() {
    return {
      show: false,
      record: {
        cycle: 'daily',
        stime: 0,
        etime: 0
      }
    }
  },
  methods: {
    open() {
      this.show = true;
    },
    close() {
      this.show = false;
    },
    onSubmit() {
      this.$emit('update', this.record);
      this.close();
    },
    getData() {
      return this.record;
    }
  }
}
</script>
