//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'CompCardSimpleNumber',
  props: {
    header: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    }
  }
};